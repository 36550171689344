import React, { useCallback } from "react";
import { useComponents } from "../hooks";

export const SearchForm = ({ filter = "", setFilter = () => {} }) => {
  const { components } = useComponents();

  const updateSearch = useCallback(
    (value) => {
      setFilter(value);
    },
    [setFilter],
  );

  return (
    <div className="rwg--sch-frm search">
      <div className="rwg--sch-ctrl rwg--ctrl">
        <input
          className="rwg--input"
          type="search"
          value={filter}
          placeholder="Search by name and content"
          onChange={(e) => updateSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateSearch(e.target.value);
            }
          }}
        />
      </div>
      <div className="rwg--sch-ico">
        <components.SearchIcon width={24} />
      </div>
    </div>
  );
};
