(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"), require("@apollo/client"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-router-dom", "@apollo/client"], factory);
	else if(typeof exports === 'object')
		exports["react-wordpress"] = factory(require("react"), require("react-router-dom"), require("@apollo/client"));
	else
		root["react-wordpress"] = factory(root["React"], root["ReactRouterDOM"], root["ApolloClient"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 